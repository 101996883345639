<template>
  <div>
    <el-row type="flex">
      <el-col :span="18" :offset="3">
        <div style="display: flex">
          <PageHeader :title="__('API Documentation')"></PageHeader>
          <el-button @click="downloadPostmanCollection" style="border: none"
            ><img :src="require('@/assets/images/postman.png')" height="50px" />
            <div style="margin-top: 15px">
              {{ __("Download Postman Collection") }}
            </div>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="18" :offset="3">
        <div class="api-documentation-container">
          <h3>{{ __("Trigger URL") }}</h3>
          <p></p>
          <p>{{ `${backendUrl}/workflow/run-now` }}</p>
          <br />

          <h3>{{ __("HTTP Method") }}</h3>
          <p>POST</p>
          <br />

          <h3>{{ __("Headers") }}</h3>
          <br />

          <el-row type="flex">
            <el-col>
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td><strong>studio-api-key</strong></td>
                    <td>
                      <strong>{{ __("REQUIRED") }}</strong>
                    </td>
                    <td>
                      {{
                        __("Any of the User API Key generated for user/account")
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-col>
          </el-row>

          <h3>{{ __("Parameters") }}</h3>
          <br />

          <el-row type="flex">
            <el-col>
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td><strong>workflow_key</strong></td>
                    <td>
                      <strong>{{ __("REQUIRED") }}</strong>
                    </td>
                    <td>
                      {{ task.workflow_task.data.api_key }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-col>
          </el-row>

          <br />
          <h3>{{ __("Usage Notes") }}</h3>
          <ul>
            <li>
              <!-- eslint-disable-next-line -->
              {{ __("Parameters selected with the workflow can be passed as POST Data.") }}
            </li>
            <li>
              <!-- eslint-disable-next-line -->
              {{ __("API requests containing variables not selected with the workflow will not be accepted.") }}
            </li>
          </ul>

          <br />
          <h3>{{ __("Example Request") }}</h3>
          <ol class="prettyprint" style="position: relative">
            <li>
              curl --location --request POST '{{
                this.backendUrl
              }}/workflow/run-now' \
            </li>
            <li>
              --header 'Content-Type: application/x-www-form-urlencoded' \
            </li>
            <li>
              --header 'studio-api-key: <b>YOUR STUDIO/USER API KEY</b>' \
            </li>
            <li>
              --data-urlencode 'workflow_key={{
                task.workflow_task.data.api_key
              }}' \
            </li>
            <li
              v-for="(passedParams, index) in task.workflow_task.data
                .passed_params.data"
              :key="index"
            >
              --data-urlencode '{{
                getPassedParameterNameFromId(passedParams.variable_id)
              }}=<b>{{ __("PASSED PARAMETER VALUE") }}</b
              >'
              <span
                v-if="
                  index !==
                    task.workflow_task.data.passed_params.data.length - 1
                "
              >
                &nbsp;\
              </span>
            </li>
            <li style="position: absolute; top: 10px; right: 15px">
              <copier
                :value="getCurlCommandForCopy"
                :success-message-override="`Curl command copied successfully`"
              ></copier>
            </li>
          </ol>
          <br />
          <h3>{{ __("Response") }}</h3>
          <p style="margin-top:10px;">
            {{ __("A response will be returned in JSON format.") }}
          </p>
          <br />
          <vue-json-pretty
            :data="successResponse"
            :highlightMouseoverNode="true"
            :highlightSelectedNode="true"
          >
          </vue-json-pretty>

          <br />
          <h3>{{ __("Errors") }}</h3>
          <br />
          <p>
            <!-- eslint-disable-next-line -->
            {{ __("The following table describes the error codes which may appear when working with the API") }}:
          </p>

          <div class="row">
            <div class="span8">
              <table class="table table- table-bordered table-striped">
                <thead>
                  <tr>
                    <th style="width:15%;">Error Code</th>
                    <th>{{ __("Error Message") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>200</td>
                    <td>{{ __("Workflow will run shortly") }}</td>
                  </tr>
                  <tr>
                    <td>403</td>
                    <td>{{ __("Workflow Key not found.") }}</td>
                  </tr>
                  <tr>
                    <td>403</td>
                    <td>{{ __("User API Key is required.") }}</td>
                  </tr>
                  <tr>
                    <td>403</td>
                    <td>
                      <!-- eslint-disable-next-line -->
                      {{ __("API key does not have permission to perform this action.") }}
                    </td>
                  </tr>
                  <tr>
                    <td>405</td>
                    <td>{{ __("HTTP POST method is supported only.") }}</td>
                  </tr>
                  <tr>
                    <td>422</td>
                    <td>
                      <strong>{{ __("Please note") }}: </strong>
                      <!-- eslint-disable-next-line -->
                      {{ __("While the text for an error message may change, the error code will stay the same.") }}
                    </td>
                  </tr>
                  <tr>
                    <td>500</td>
                    <td>
                      <!-- eslint-disable-next-line -->
                      {{ __("Something is broken. Please contact your service provider.") }}
                    </td>
                  </tr>
                  <tr>
                    <td>501</td>
                    <td>{{ __("Method not implemented.") }}</td>
                  </tr>
                  <tr>
                    <td>504</td>
                    <td>
                      <!-- eslint-disable-next-line -->
                      {{ __("The request couldn't be serviced due to some failure within our stack. Try again later.") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import _ from "lodash";
import { mapGetters } from "vuex";
import Copier from "@/components/Copier";
import PageHeader from "@/components/PageHeader";
import { getWorkflowPostmanCollection } from "@/api/tasks";
import FileDownloader from "@/mixins/FileDownloader";

export default {
  components: {
    VueJsonPretty,
    Copier,
    PageHeader
  },
  mixins: [FileDownloader],
  props: {
    task: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_BASE_API,
      successResponse: {
        status_code: 200,
        message: __("Workflow will run shortly")
      }
    };
  },
  computed: {
    ...mapGetters("variables", {
      passedParameterVariables: "passedParameterVariables",
      getPassedParameterNameFromId: "getPassedParameterNameFromId"
    }),
    getCurlCommandForCopy() {
      let curlCommand = `curl -location --request POST '${this.backendUrl}/workflow/run-now --header  'Content-Type: application/x-www-form-urlencoded' --data-urlencode 'ac_api_key=YOUR ACCOUNT API KEY' --data-urlencode 'workflow_key=${this.task.workflow_task.data.api_key}' `;
      _.forEach(
        this.task.workflow_task.data.passed_params.data,
        passedParam => {
          curlCommand += `--data-urlencode '${this.getPassedParameterNameFromId(
            passedParam.variable_id
          )}=PASSED PARAMETER VALUE' `;
        }
      );
      return curlCommand;
    }
  },
  methods: {
    downloadPostmanCollection() {
      getWorkflowPostmanCollection(this.task).then(res => {
        this.downloadFile(
          res.data,
          _.snakeCase(this.task.task_name) + ".postman_collection.json"
        );
      });
    }
  }
};
</script>

<style scoped>
.api-documentation-container {
  position: relative;
  margin: 15px 0;
  padding: 39px 19px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.api-documentation-container::after {
  content: "API Documentation";
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #9da0a4;
  -webkit-border-radius: 4px 0 4px 0;
  -moz-border-radius: 4px 0 4px 0;
  border-radius: 4px 0 4px 0;
}

table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table th {
  font-weight: bold;
}

.table thead th {
  vertical-align: bottom;
}

.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}

.table tbody + tbody {
  border-top: 2px solid #dddddd;
}

.table .table {
  background-color: #ffffff;
}

.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}

.table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.table-bordered th,
.table-bordered td {
  border-left: 1px solid #dddddd;
}

.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}

.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
}

.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
}

.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}

.prettyprint {
  padding: 12px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 6px;
  list-style: none;
}
</style>
